import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from 'src/components/Section'
import { GatsbyImage } from 'gatsby-plugin-image'
import Video from 'src/components/Video'
import Grid from 'src/components/Grid'
import { typography, mq, colors, globals, util, animations } from 'src/styles'
import TextLockup from 'src/components/TextLockup'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import { use100vh } from 'react-div-100vh'
import ArrowDownSvg from 'src/assets/images/arrow-down.svg'
import ArrowDownSimpleSvg from 'src/assets/images/arrow-down-minimal.svg'

const Wrapper = styled(Section)`
${ ({ padded }) => !padded ? `
  padding: 0 !important;
` : '' }
${ ({ overlayTextColor }) =>
    overlayTextColor === 'dark' ? `
    color: ${ colors.textColor };
  ` : `
    color: ${ colors.white };
  ` }
  ${ ({ height, stackMobile }) => stackMobile ? `
    ${ mq.largeAndBelow } {
      height: ${ height };
    }
  ` : '' }
`

const ArrowWrapper = styled.div`
  position: absolute;
  bottom: ${ 100 / 20 }vw;
  left: ${ 100 / 14 }vw;
  z-index: 2;
  ${ util.responsiveStyles('width', 32, 26, 22, 18) }
  animation: ${ animations.bounce } 3s infinite;
  ${ mq.largeAndBelow } {
    position: static;
    ${ ({ overlayTextAlignment }) => overlayTextAlignment === 'center' ? `
      margin: 40px auto 0;
    ` : `
      margin: 40px 0 0;
    ` }
  }
`

const ArrowDown = styled.div`
  color: inherit;
  * {
    fill: currentcolor;
  }
`

const WideMediaWrap = styled.div`
  ${ ({ height }) =>
    height !== 'auto' ? `
    position: relative;
    z-index: 2;
  ` : '' }
  ${ ({ padBottom }) => padBottom ? `
    ${ mq.largeAndBelow } {
      padding-bottom: 50px;
    }
  ` : '' }
  ${ ({ isHome, isFirstSection }) => isHome && isFirstSection ? `
    img {
      object-position: bottom;
    }
  ` : '' }
  ${ ({ stackMobile }) => stackMobile ? `
    ${ mq.largeAndBelow } {
      height: 100%;
      display: flex;
      flex-direction: column;
      > div:first-of-type {
        flex-grow: 1;
      }
      > div:last-of-type {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  ` : '' }
`

const ContentWrap = styled.div`
  position: relative;
  ${ ({ stackMobile }) => stackMobile ? `
    ${ mq.largeAndBelow } {
      display: flex;
      flex-direction: column;
      height: 100%;
      .gatsby-image-wrapper {
        > div:first-of-type {
          display: none !important;
        }
      }
    }
  ` : '' }
`

const MediaItem = styled.div`
  z-index: 1;
  width: 100%;
  ${ ({ stackMobile, isFirstSection }) => stackMobile ? `
    ${ mq.largeAndBelow } {
      ${ isFirstSection ? `
        clip-path: url(#raggedEdgeBottom);
      ` : `
        clip-path: url(#raggedEdgeTopBottom);
      ` }
    }
  ` : '' }
  ${ ({ overlay, height, stackMobile }) => (overlay && height !== 'auto') ? `

    ${ stackMobile === true ? `
      position: absolute;
      ${ mq.largeAndBelow } {
        // position: relative;
        // min-height: 0;
        // flex-grow: 1;
        min-height: ${ height };
        height: auto;
        > div, 
        > div > div,
        img {
          min-height: 0 !important;
        }
      }
    ` : `
      position: absolute;
    ` }

    top: 0;
    left: 0;
    height: 100%;
  ` : `
    position: relative;
  ` }
  ${ ({ height }) =>
    height !== 'auto' ? `
    height: 100%;
    min-height: ${ height };
    > div, 
    > div > div {
      height: 100% !important;
      min-height: ${ height };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      min-height: ${ height };
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      min-height: ${ height };
    }
  ` : `
    height: 100%;
  ` }
`

const OverlayContent = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  ${ ({ height }) =>
    height === 'auto' ? `
    position: absolute;
  ` : `
    position: relative;

  ` }
  ${ ({ headlineStyle }) => headlineStyle === 'handwritten' ? `
    h1, h2, h3, h4 {
      font-family: ${ typography.secondaryFont };
      text-transform: lowercase;
      line-height: .625em;
      max-width: 8em !important;
      letter-spacing: -0.03em;
      ${ mq.largeAndBelow } {
        font-size: 10vw !important;
      }
      ${ mq.mediumAndBelow } {
        font-size: 12vw !important;
        margin-bottom: -12px;
        max-width: 5em !important
      }
      ${ mq.smallAndBelow } {
        font-size: 19vw !important;
        margin-bottom: -24px;
      }
    }
    h1 { ${ util.responsiveStyles('font-size', 60 * 1.8, 40 * 1.8, 37 * 1.8, 30 * 1.8) } }
    h2 { ${ util.responsiveStyles('font-size', 54 * 1.8, 36 * 1.8, 32 * 1.8, 24 * 1.8) } }
    h3 { ${ util.responsiveStyles('font-size', 48 * 1.8, 32 * 1.8, 27 * 1.8, 22 * 1.8) } }
    h4 { ${ util.responsiveStyles('font-size', 32 * 1.8, 22 * 1.8, 18 * 1.8, 16 * 1.8) } }
  ` : '' }
  ${ ({ height, stackMobile }) => height !== 'auto' ? `

    ${
      stackMobile === true ? `
        ${ mq.largeAndBelow } {
          // min-height: unset;
          margin-bottom: 10vh;
          min-height: 0;
          margin: 0;
          padding: 0;
          flex-grow: 0;
          flex-shrink: 0;
          justify-content: flex-end;
        }
        min-height: ${ height };
      ` : `
        min-height: ${ height };
      `
    }

  ` : '' }
  justify-content: ${ ({ verticalPlacement }) => {
    if (!verticalPlacement) return 'center'
    if (verticalPlacement === 'top') return 'flex-start'
    if (verticalPlacement === 'bottom') return 'flex-end'
    else return verticalPlacement
  } };
  text-align: ${ ({ overlayTextAlignment }) => overlayTextAlignment || 'left' };
  h1,
  h2,
  h3 {
    max-width: 12em;
  }
  h4,
  h4 {
    max-width: 12em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 36em;
  }
`

const OverlaySection = styled.div`
  ${ globals.verticalSpacing('padding-bottom') }
  ${ globals.verticalSpacing('padding-top') }
  .button:not(:hover) {
    // ${ mq.largeAndBelow } {
    //   color: var(--bg-color);
    // }
  }
  ${ ({ downArrow }) => downArrow ? `
    ${ mq.largeAndUp } {
      ${ globals.verticalSpacing('padding-bottom', 2) }
    }
  ` : '' }
  ${ ({ isFirstSection, stackMobile }) =>
    isFirstSection && `
    ${
      stackMobile === true ? `
        ${ mq.largeAndBelow } {
          padding-top: 20px;
          padding-bottom: 0;
          margin-bottom: -20px;
        }
        ${ mq.largerAndUp } {
          ${ globals.verticalSpacing('padding-top', 1) }
        }
      ` : `
        ${ globals.verticalSpacing('padding-top', 1) }
      `
    }
  ` }
`

function getHorizontalPlacementGridValues ({ fullWidth, horizontalPlacement }) {
  if (!fullWidth) {
    return {
      left: {
        medium: '2 [10] 2',
        large: '2 [8] 4',
        larger: '2 [6] 6',
      },
      center: {
        medium: '2 [10] 2',
        large: '3 [8] 3',
        larger: '4 [6] 4',
      },
      right: {
        medium: '7 [5] 2',
        large: '7 [5] 2',
        larger: '7 [5] 2',
      },
    }[horizontalPlacement]
  } else {
    return {
      left: {
        medium: 'container',
        large: 'container',
        larger: 'container',
      },
      center: {
        medium: '2 [10] 2',
        large: '2 [10] 2',
        larger: '2 [10] 2',
      },
      right: {
        medium: '7 [6] 1',
        large: '7 [6] 1',
        larger: '7 [6] 1',
      },
    }[horizontalPlacement]
  }
}

const WideMedia = ({
  media,
  mediaMedium,
  mediaSmall,
  videoPoster,
  width,
  theme,
  prevTheme,
  nextTheme,
  height,
  headlineStyle,
  eyebrow,
  text,
  paragraphSize,
  actions,
  overlayPlacement,
  overlayTextAlignment,
  isFirstSection,
  overlayTextColor,
  stackMobile = false,
  index,
  downArrow,
  isHome
}) => {
  if (!media) {
    return false
  }

  if (!height) {
    height = 'auto'
  }

  const fullWidth = width === 'fullWidth'

  const getMediaType = type => {
    return type.includes('video') ? 'video' : 'image'
  }

  const fullHeight = use100vh()

  const heightValues = {
    auto: 'auto',
    fullHeight: fullHeight && isFirstSection ? fullHeight + 20 + 'px' : '100vh',
    mediumHeight: '85vh',
    shortHeight: '50vh',
  }

  const [verticalPlacement, horizontalPlacement] = (
    overlayPlacement || 'center center'
  ).split(' ')
  const overlayGridSettings = getHorizontalPlacementGridValues({
    fullWidth,
    horizontalPlacement,
  })
  const hasOverlay = eyebrow || text || actions

  const renderMedia = (mediaItem, size, hasOverlay, autoHeight, stackMobile, isFirstSection) => {
    if (size === 'small' && !mediaItem) {
      mediaItem = mediaMedium || media
    } else if (size === 'medium' && !mediaItem) {
      mediaItem = media
    }
    if (mediaItem) {
      const contentType = mediaItem.file ? getMediaType(mediaItem.file.contentType) : 'image'
      if (contentType === 'video') {
        return <MediaItem
          overlay={hasOverlay}
          height={heightValues[height]}
          stackMobile={stackMobile}
          isFirstSection={isFirstSection}
        >
          <video autoPlay muted loop playsInline poster={videoPoster?.file?.url}>
            <source src={mediaItem.file.url} type="video/mp4" />
          </video>
        </MediaItem>
      } else {
        return <MediaItem
          overlay={hasOverlay}
          image={mediaItem.gatsbyImageData}
          loading={isFirstSection ? 'eager' : 'lazy'}
          height={heightValues[height]}
          alt={mediaItem.file.fileName}
          sizes='4000px'
          as={GatsbyImage}
          quality={100}
          stackMobile={stackMobile}
          isFirstSection={isFirstSection}
        />
      }
    }
    return false
  }

  return (
    <Wrapper
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      setTheme={theme}
      padded={!fullWidth}
      overlayTextColor={overlayTextColor}
      index={index}
      isFirstSection={isFirstSection}
      roughEdgeClip
      height={heightValues[height]}
      stackMobile={stackMobile}
    >
      <WideMediaWrap
        height={heightValues[height]}
        padBottom={nextTheme && nextTheme !== theme}
        stackMobile={stackMobile}
        isHome={isHome}
        isFirstSection={isFirstSection}
      >
        <Grid
          small={fullWidth ? '[1]' : 'container'}
          medium={fullWidth ? '[1]' : 'container'}
          large={fullWidth ? '[1]' : 'container'}
        >
          <ContentWrap stackMobile={stackMobile}>
            <ResponsiveComponent
              small={renderMedia(mediaSmall, 'small', hasOverlay, height === 'auto', stackMobile, isFirstSection)}
              medium={renderMedia(mediaMedium, 'medium', hasOverlay, height === 'auto', stackMobile, isFirstSection)}
              large={renderMedia(media, 'large', hasOverlay, height === 'auto', stackMobile, isFirstSection)}
            />
            {hasOverlay && (
              <OverlayContent
                padded={!fullWidth}
                overlayTextAlignment={overlayTextAlignment}
                verticalPlacement={verticalPlacement}
                height={heightValues[height]}
                stackMobile={stackMobile}
                headlineStyle={headlineStyle}
              >
                <OverlaySection
                  isFirstSection={isFirstSection}
                  stackMobile={stackMobile}
                  downArrow={downArrow}
                >
                  <Grid
                    small="container"
                    medium="container"
                    large="container"
                    {...overlayGridSettings}
                  >
                    <ResponsiveComponent
                      small={
                        <>
                          <TextLockup
                            // css={stackMobile ? css`color: var(--text-color);` : false}
                            eyebrow={eyebrow}
                            text={text}
                            textSize={paragraphSize}
                            actions={!isHome && !isFirstSection ? actions : false}
                            alignment={stackMobile ? 'center' : overlayTextAlignment}
                            setTheme={theme}
                          />
                          <ArrowWrapper alignment={overlayTextAlignment}>
                            <ArrowDown as={ArrowDownSimpleSvg}/>
                          </ArrowWrapper>
                        </>
                      }
                      medium={
                        <TextLockup
                          // css={stackMobile ? css`color: var(--text-color);` : false}
                          eyebrow={eyebrow}
                          text={text}
                          textSize={paragraphSize}
                          actions={actions}
                          alignment={stackMobile ? 'center' : overlayTextAlignment}
                          setTheme={theme}
                        />
                      }
                      large={
                        <TextLockup
                          eyebrow={eyebrow}
                          text={text}
                          textSize={paragraphSize}
                          actions={actions}
                          alignment={overlayTextAlignment}
                          setTheme={theme}
                        />
                      }
                    />
                  </Grid>
                </OverlaySection>
              </OverlayContent>
            )}
          </ContentWrap>
        </Grid>
        {downArrow && (
          <ArrowWrapper alignment={overlayTextAlignment}>
            <ResponsiveComponent
              small={<></>}
              large={<ArrowDown as={ArrowDownSvg}/>}
            />
          </ArrowWrapper>
        )}
      </WideMediaWrap>
    </Wrapper>
  )
}

WideMedia.defaultProps = {
  width: 'margins',
  height: 'auto',
}

export default WideMedia
